import useCatchHandler from "@/services/catchErrorHandler"
import useOAuth from "@/services/oauth"
import { useStore } from "@/store"
import { ActionTypes as AssessmentActions } from "@/store/modules/assessment/actions"
import { ActionTypes as StatActions } from "@/store/modules/global/actions"
import axios from "axios"
import { computed } from "vue"
import { EXHAUSTED_THE_LIMIT, SUPPORT_EMAIL } from "../core/constants"
// @ts-ignore
import { toast } from "vue3-toastify"
import "vue3-toastify/dist/index.css"

export default function useApiAuth() {
    const { handleError } = useCatchHandler()
    const { API_PREFIX } = useOAuth()
    const store = useStore()
    const getApiRequestHeader = computed(
        () => store.getters.getApiRequestHeader
    )
    const getAuthUserEmail = computed(() => store.getters.getAuthUser?.email)
    const getUserCountryCodeIP = computed(
        () => store.getters.getCurrentUserCountryDialCode
    )
    const getUserTimezone = computed(() => store.getters.getCurrentUserTimezone)
    const apiRegisterUser = async (
        provider: string = "email",
        email: string,
        phoneExt: number,
        phone: string,
        firstName: string,
        lastName: string,
        userJobTitle: string,
        workspaceName: string,
        workspaceUrl: string,
        isTermOfUseAccepted: boolean,
        isPromotionalEmailAccepted: boolean,
        utmSource: string,
        utmMedium: string,
        utmCampaign: string,
        utmTerm: string,
        utmContent: string,
        language: string,
        accessKey?: string, // accessKey will be passed as null when this method is called from the user with invitation (InviteeRegistrationPage.vue)
        inviteKey?: string,
        timeZone?: string
    ) => {
        let key = {}
        // accessKey will be passed as null when this method is called from the user with invitation (InviteeRegistrationPage.vue)
        if (inviteKey) {
            key = { inviteKey }
        } else {
            // accessKey will be passed as non-null when this method is called from the (CreateWorkspaceForm.vue)
            key = { accessKey }
        }
        phoneExt = getUserCountryCodeIP.value
            ? Number(getUserCountryCodeIP.value)
            : 91
        timeZone = getUserTimezone.value
            ? getUserTimezone.value
            : "Asia/Kolkata"

        if (getAuthUserEmail.value == SUPPORT_EMAIL) {
            phoneExt = 1
        }
        try {
            const body = {
                provider,
                email,
                phoneExt,
                phone,
                firstName,
                lastName,
                userJobTitle,
                workspaceName,
                workspaceUrl,
                isTermOfUseAccepted,
                isPromotionalEmailAccepted,
                utmSource,
                utmMedium,
                utmCampaign,
                utmTerm,
                utmContent,
                language,
                ...key,
                domainName: window.location.hostname,
                timeZone,
            }
            return await axios.post(
                `${API_PREFIX}/public/auth/registration`,
                body
            )
        } catch (err) {
            const { statusCode } = err.response!.data!.error
            // 400 denotes the error where namespace is already taken, that we need to show under the workspaceUrl field,
            // & not in notification bar
            if (
                [
                    "Workspace Name Taken",
                    "Namespace Taken",
                    "Record exist",
                ].includes(err.response.data.error.message)
            ) {
                return 403
            }
            if (err.code === "ERR_NETWORK") {
                toast(EXHAUSTED_THE_LIMIT, {
                    type: "error",
                    autoClose: 5000,
                    position: "bottom-left",
                })
            }
            handleError(err.response)
            return -1
        }
    }

    const apiRegisterUserVerifyOtp = async (
        provider: string = "email",
        email: string,
        otp: number
    ) => {
        try {
            const body = {
                provider,
                email,
                otp,
                domainName: window.location.hostname,
            }
            return await axios.post(
                `${API_PREFIX}/public/auth/otp/verify`,
                body
            )
        } catch (err) {
            if (err.code === "ERR_NETWORK") {
                toast(EXHAUSTED_THE_LIMIT, {
                    type: "error",
                    autoClose: 5000,
                    position: "bottom-left",
                })
            }
            return err.response.data
        }
    }

    const apiRegisterUserSendOtp = async (
        provider: string = "email",
        email: string,
        action: string,
        resendCode: boolean = false,
        firstName: string = "",
        lastName: string = "",
        recaptchaToken: string,
        language: string
    ) => {
        try {
            const body = {
                provider,
                email,
                action,
                resendCode,
                firstName,
                lastName,
                recaptchaToken,
                language,
                domainName: window.location.hostname,
            }
            return await axios.post(`${API_PREFIX}/public/auth/otp`, body)
        } catch (err) {
            if (err.code === "ERR_NETWORK") {
                toast(EXHAUSTED_THE_LIMIT, {
                    type: "error",
                    autoClose: 5000,
                    position: "bottom-left",
                })
            }
            return err.response.data
        }
    }

    const apiLoginUser = async (
        provider: string = "email",
        email: string,
        accessKey: string,
        workspaceUrl: string
    ) => {
        try {
            return await axios.post(`${API_PREFIX}/public/auth/login`, {
                provider,
                email,
                accessKey,
                workspaceUrl,
                domainName: window.location.hostname,
                timeZone: getUserTimezone.value
                    ? getUserTimezone.value
                    : "Asia/Kolkata",
            })
        } catch (err) {
            if (err.code === "ERR_NETWORK") {
                toast(EXHAUSTED_THE_LIMIT, {
                    type: "error",
                    autoClose: 5000,
                    position: "bottom-left",
                })
            }
            handleError(err.response)
            return -1
        }
    }

    const apiRegisterWorkspace = async (
        name: string,
        workspaceUrl: string,
        userJobTitle: string
    ) => {
        try {
            const body = {
                name,
                workspaceUrl,
                userJobTitle,
            }
            return await axios.post(`${API_PREFIX}/workspace/register`, body)
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiAddNewWorkspace = async (
        provider: string = "email",
        email: string,
        phoneExt: number = 91,
        phone: string = "",
        firstName: string,
        lastName: string,
        userJobTitle: string,
        workspaceName: string,
        workspaceUrl: string,
        isTermOfUseAccepted: boolean,
        isPromotionalEmailAccepted: boolean,
        language: string
    ) => {
        phoneExt = getUserCountryCodeIP.value
            ? Number(getUserCountryCodeIP.value)
            : 91

        if (getAuthUserEmail.value == SUPPORT_EMAIL) {
            phoneExt = 1
        }
        try {
            const body = {
                provider,
                email,
                phoneExt,
                phone,
                firstName,
                lastName,
                userJobTitle,
                workspaceName,
                workspaceUrl,
                isTermOfUseAccepted,
                isPromotionalEmailAccepted,
                language,
                domainName: window.location.hostname,
            }
            return await axios.post(
                `${API_PREFIX}/workspace/create`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            const { statusCode } = response!.data!.error
            // 400 denotes the error where namespace is already taken, that we need to show under the workspaceUrl field,
            // & not in notification bar
            if (statusCode === 400) {
                return 400
            }
            if (statusCode === 403) {
                return 403
            }
            handleError(response)
            return -1
        }
    }

    const apiWorkspaceList = async () => {
        try {
            return await axios.get(`${API_PREFIX}/workspace/list`)
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    // the permanent workspace session token is received in the response of this api
    const apiWorkspaceLogin = async (
        workspaceKey: string,
        workspaceUrl: string
    ) => {
        try {
            const body = {
                workspaceKey,
                workspaceUrl,
            }
            return await axios.post(
                `${API_PREFIX}/public/auth/workspace/login`,
                body
            )
        } catch (err) {
            if (err.code === "ERR_NETWORK") {
                toast(EXHAUSTED_THE_LIMIT, {
                    type: "error",
                    autoClose: 5000,
                    position: "bottom-left",
                })
            }
            handleError(err.response)
            return -1
        }
    }

    const apiUserProfileView = async () => {
        try {
            let response: any = await axios.get(
                `${API_PREFIX}/workspace/user/profile`,
                getApiRequestHeader.value
            )
            return response.data
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiWorkspaceDetails = async () => {
        getApiRequestHeader.value
        try {
            let response: any = await axios.get(
                `${API_PREFIX}/workspace/profile`,
                getApiRequestHeader.value
            )
            const email = getAuthUserEmail.value
            const isSpecialUser =
                email.includes("hnrtech") || email.includes("testlify")
            const optionLimit = isSpecialUser
                ? 9999
                : response.data?.workSpaceLimit?.optionAdded
            const questionLimit = isSpecialUser
                ? 9999
                : response.data?.workSpaceLimit?.questionAdded

            store.dispatch(AssessmentActions.UPDATE_OPTION_LIMIT, optionLimit)
            store.dispatch(
                AssessmentActions.UPDATE_QUESTION_LIMIT,
                questionLimit
            )

            return response.data
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiCandidateLabels = async (
        query: string,
        limit: number,
        skip: number
    ) => {
        try {
            let response: any = await axios.get(
                `${API_PREFIX}/workspace/label?limit=${limit}&skip=${skip}&query=${query}`,
                getApiRequestHeader.value
            )
            return response.data
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiCreateCandidateLabel = async (title: string) => {
        try {
            const body = { title }
            let response: any = await axios.post(
                `${API_PREFIX}/workspace/label`,
                body,
                getApiRequestHeader.value
            )
            return response.data
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiUpdateCandidateLabel = async (id: string, title: string) => {
        try {
            const body = { id, title }
            let response: any = await axios.put(
                `${API_PREFIX}/workspace/label/${id}`,
                body,
                getApiRequestHeader.value
            )
            return response.data
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiDeleteCandidateLabel = async (id: string) => {
        try {
            let response: any = await axios.delete(
                `${API_PREFIX}/workspace/label/${id}`,
                getApiRequestHeader.value
            )
            return response.data
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiUserProfileEdit = async (body: {
        firstName?: string
        lastName?: string
        phoneExt?: number
        phone?: string
        defaultLanguage?: string
        accessibilitySettings?: boolean
        timeZone?: string
    }) => {
        try {
            const response: any = await axios.put(
                `${API_PREFIX}/workspace/user/profile`,
                body,
                getApiRequestHeader.value
            )
            return response.data
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiUpdateWorkspaceAppearance = async (
        brandColor: string,
        logo: string,
        favicon: string,
        customImage: string
    ) => {
        try {
            const body = {
                logo: logo ? logo : "",
                favicon: favicon ? favicon : "",
                brandColor: brandColor ? brandColor : "",
                customImage: customImage ? customImage : "",
            }
            let response: any = await axios.put(
                `${API_PREFIX}/workspace/profile/appearance`,
                body,
                getApiRequestHeader.value
            )
            return response.data
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiDeleteWorkspace = async () => {
        try {
            let response: any = await axios.delete(
                `${API_PREFIX}/workspace`,
                getApiRequestHeader.value
            )
            return response.data
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiUpdateWorkspaceName = async (name: string) => {
        try {
            const body = {
                name,
            }
            let response: any = await axios.put(
                `${API_PREFIX}/workspace/profile`,
                body,
                getApiRequestHeader.value
            )
            return response.data
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiExtendTrial = async () => {
        try {
            let response: any = await axios.put(
                `${API_PREFIX}/workspace/profile/extendtrial`,
                null,
                getApiRequestHeader.value
            )
            return response.data
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiUpdateNotificationEmails = async (
        value: boolean,
        adminValue: boolean
    ) => {
        try {
            const body = {
                candidateNotificationAllowed: value,
                administratorNotificationAllowed: adminValue,
            }
            let response: any = await axios.put(
                `${API_PREFIX}/workspace/profile`,
                body,
                getApiRequestHeader.value
            )
            return response.data
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    // getApiRequestHeader is purposely not used as request header
    const apiSwitchWorkspace = async (
        sessionCookie: string,
        limit: number,
        skip: number,
        query: string,
        isActive: boolean
    ) => {
        const requestHeaders = {
            headers: {
                Authorization: `Bearer ${sessionCookie}`,
            },
        }
        try {
            let response: any = await axios.get(
                `${API_PREFIX}/workspace/switch?limit=${limit}&skip=${skip}&query=${encodeURIComponent(
                    query
                )}&isActive=${isActive}`,
                requestHeaders
            )
            return response.data
        } catch ({ response }) {
            const errors: any = response!.data!.error
            const { statusCode } = errors
            // token expired
            if (statusCode === 401) {
                return 401
            }
            handleError(response)
            return -1
        }
    }

    const apiGetCountryCode = async (getIpAddress) => {
        try {
            return await axios.get(
                `${API_PREFIX}/public/user/country?ip=${getIpAddress}`
            )
        } catch (err) {
            if (err.code === "ERR_NETWORK") {
                toast(EXHAUSTED_THE_LIMIT, {
                    type: "error",
                    autoClose: 5000,
                    position: "bottom-left",
                })
            }
            handleError(err.response)
            return -1
        }
    }
    const apiGenerateApiKey = async () => {
        try {
            return await axios.post(
                `${API_PREFIX}/workspace/apikey/generate`,
                {},
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiDeleteApiKey = async () => {
        try {
            return await axios.delete(
                `${API_PREFIX}/workspace/apikey`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiSocialLogin = async (
        isRegister: boolean,
        code: string,
        redirectUri: string,
        state: string,
        provider: string
    ) => {
        try {
            const body = {
                code,
                redirectUri,
                state,
                provider,
                isRegister,
            }
            const response: any = await axios.post(
                `${API_PREFIX}/public/auth/social`,
                body
            )
            return response
        } catch (err) {
            if (err.code === "ERR_NETWORK") {
                toast(EXHAUSTED_THE_LIMIT, {
                    type: "error",
                    autoClose: 5000,
                    position: "bottom-left",
                })
            }
            return err.response.data
        }
    }
    const apiPhoneVerificationExt = async () => {
        try {
            let response: any = await axios.get(
                `${API_PREFIX}/public/auth/number/verify`
            )
            return response.data
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiUserPhoneNumberOtp = async (
        phoneNumber: string,
        channel: string
    ) => {
        try {
            let response: any = await axios.get(
                `${API_PREFIX}/public/auth/phonenumber/otp?phoneNumber=${phoneNumber}&channel=${channel}`
            )
            return response.data
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiUserPhoneNumberVerify = async (
        phoneNumber: string,
        code: string,
        email: string
    ) => {
        const body = {
            phoneNumber,
            code,
            email,
        }
        try {
            let response: any = await axios.post(
                `${API_PREFIX}/public/auth/phonenumber/otp`,
                body
            )
            return response
        } catch ({ response }) {
            return response
        }
    }

    return {
        apiRegisterUser,
        apiLoginUser,
        apiRegisterWorkspace,
        apiCreateCandidateLabel,
        apiUpdateCandidateLabel,
        apiDeleteCandidateLabel,
        apiWorkspaceList,
        apiRegisterUserSendOtp,
        apiRegisterUserVerifyOtp,
        apiWorkspaceLogin,
        apiUserProfileView,
        apiUserProfileEdit,
        apiUpdateWorkspaceAppearance,
        apiUpdateWorkspaceName,
        apiExtendTrial,
        apiDeleteApiKey,
        apiUpdateNotificationEmails,
        apiDeleteWorkspace,
        apiSwitchWorkspace,
        apiCandidateLabels,
        apiGetCountryCode,
        apiGenerateApiKey,
        apiWorkspaceDetails,
        apiSocialLogin,
        apiAddNewWorkspace,
        apiPhoneVerificationExt,
        apiUserPhoneNumberOtp,
        apiUserPhoneNumberVerify,
    }
}
